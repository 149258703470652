import '../scss/forBS5.scss';
import Dropdown from 'bootstrap/js/dist/dropdown';
/* eslint-disable no-unused-vars */
import Collapse from 'bootstrap/js/dist/collapse';

// blog posts added in the backend!
// on page load
document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM loaded');

  const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
  console.log('Found dropdowns:', dropdownElementList);

  dropdownElementList.forEach((dropdownToggleEl) => {
    // eslint-disable-next-line no-unused-vars
    const dropdown = new Dropdown(dropdownToggleEl);
  });
});
